<template>
    <div>
      <service-list></service-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  
  